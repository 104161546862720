import React from "react";
import {
  Box,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Grid,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "components/ui/Container";
import Seo from "components/Seo";

const DesignGalleryPage: React.FC = () => {
  return (
    <Box py={10}>
      <Seo
        title="Design Gallery"
        description="Collect decorative models in design libary that easily apply to your design project."
        keywords="home decorative models, collect decorative models, find decorative models,study room design, living room design, kitchen & dining room design, bedroom design, children's room design"
      />

      <Container fluid>
        <Stack direction="column" spacing={10}>
          <Text as="h1" fontSize="3xl" fontWeight="bold" textAlign="center">
            Scene Models
          </Text>

          <Tabs colorScheme="orange">
            <TabList flexWrap="wrap">
              <Tab>Study</Tab>
              <Tab>Living Room</Tab>
              <Tab>Kitchen &amp; Dining Room</Tab>
              <Tab>Bedroom</Tab>
              <Tab>Childrens' Room</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Grid
                  gap={6}
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                >
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-1-aihouse-india.jpg"
                    alt="study-room-1-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-2-aihouse-india.jpg"
                    alt="study-room-2-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-3-aihouse-india.jpg"
                    alt="study-room-3-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-4-aihouse-india.jpg"
                    alt="study-room-4-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-5-aihouse-india.jpg"
                    alt="study-room-5-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-6-aihouse-india.jpg"
                    alt="study-room-6-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-7-aihouse-india.jpg"
                    alt="study-room-7-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-8-aihouse-india.jpg"
                    alt="study-room-8-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-9-aihouse-india.jpg"
                    alt="study-room-9-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-10-aihouse-india.jpg"
                    alt="study-room-10-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-11-aihouse-india.jpg"
                    alt="study-room-11-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/study-room/study-room-12-aihouse-india.jpg"
                    alt="study-room-12-aihouse-india"
                  />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid
                  gap={6}
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                >
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-1-aihouse-india.jpg"
                    alt="living-room-1-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-2-aihouse-india.jpg"
                    alt="living-room-2-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-3-aihouse-india.jpg"
                    alt="living-room-3-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-4-aihouse-india.jpg"
                    alt="living-room-4-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-5-aihouse-india.jpg"
                    alt="living-room-5-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-6-aihouse-india.jpg"
                    alt="living-room-6-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-7-aihouse-india.jpg"
                    alt="living-room-7-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-8-aihouse-india.jpg"
                    alt="living-room-8-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-9-aihouse-india.jpg"
                    alt="living-room-9-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-10-aihouse-india.jpg"
                    alt="living-room-10-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-11-aihouse-india.jpg"
                    alt="living-room-11-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/living-room/living-room-12-aihouse-india.jpg"
                    alt="living-room-12-aihouse-india"
                  />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid
                  gap={6}
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                >
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-1-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-1-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-2-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-2-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-3-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-3-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-4-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-4-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-5-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-5-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-6-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-6-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-7-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-7-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-8-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-8-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-9-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-9-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-10-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-10-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-11-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-11-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/kitchen-room-and-dining-room/kitchen-room-dining-room-12-aihouse-india.jpg"
                    alt="kitchen-room-dining-room-12-aihouse-india"
                  />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid
                  gap={6}
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                >
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-1-aihouse-india.jpg"
                    alt="bedroom-1-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-2-aihouse-india.jpg"
                    alt="bedroom-2-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-3-aihouse-india.jpg"
                    alt="bedroom-3-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-4-aihouse-india.jpg"
                    alt="bedroom-4-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-5-aihouse-india.jpg"
                    alt="bedroom-5-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-6-aihouse-india.jpg"
                    alt="bedroom-6-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-7-aihouse-india.jpg"
                    alt="bedroom-7-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-8-aihouse-india.jpg"
                    alt="bedroom-8-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-9-aihouse-india.jpg"
                    alt="bedroom-9-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-10-aihouse-india.jpg"
                    alt="bedroom-10-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-11-aihouse-india.jpg"
                    alt="bedroom-11-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/bedroom/bedroom-12-aihouse-india.jpg"
                    alt="bedroom-12-aihouse-india"
                  />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid
                  gap={6}
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                  }}
                >
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-1-aihouse-india.jpg"
                    alt="children-room-1-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-2-aihouse-india.jpg"
                    alt="children-room-2-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-3-aihouse-india.jpg"
                    alt="children-room-3-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-4-aihouse-india.jpg"
                    alt="children-room-4-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-5-aihouse-india.jpg"
                    alt="children-room-5-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-6-aihouse-india.jpg"
                    alt="children-room-6-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-7-aihouse-india.jpg"
                    alt="children-room-7-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-8-aihouse-india.jpg"
                    alt="children-room-8-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-9-aihouse-india.jpg"
                    alt="children-room-9-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-10-aihouse-india.jpg"
                    alt="children-room-10-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-11-aihouse-india.jpg"
                    alt="children-room-11-aihouse-india"
                  />
                  <StaticImage
                    src="../images/banners/design-gallery/children-room/children-room-12-aihouse-india.jpg"
                    alt="children-room-12-aihouse-india"
                  />
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
    </Box>
  );
};

export default DesignGalleryPage;
